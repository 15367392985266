import { FC, RefObject, createRef, forwardRef, useEffect, useState } from 'react';
import styles from './inputs.module.scss';
import { Icon } from '../icons/icon';
import { themeVariables } from '../styles/theme';
import classNames from 'classnames';
import { Loader } from '../loaders/loader';
import { InputError, InputValidationRule, UseFormReturn } from './form.hook';

export type IInputProps = {
  id: string;
  name: string;
  label: string;
  error?: InputError | null;
  defaultValue?: string | number;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
  value?: string;
  type?: string;
  ghost?: boolean;
  maxLength?: number;
  disabled?: boolean;
  rules?: InputValidationRule[];
  form?: UseFormReturn;
  placeholder?: string;
  hidden?: boolean;
  suffix?: React.ReactNode;
  min?: number;
  max?: number;
  inputMaxWidth?: string;
}

type InputGroupProps = {
  spacing?: 2 | 4 | 6 | 8 | 10 | 14;
  children: React.ReactNode;
  noBottomSpacing?: boolean;
}

export const InputGroup: FC<InputGroupProps> = ({ children, noBottomSpacing, spacing = 2 }) => {
  return <div className={classNames({
    [styles[`inputGroup${spacing}`]]: true,
    [styles.noBottomSpacing]: noBottomSpacing,
  })}>{children}</div>;
};

export const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>((props, inputRef) => {
  const {
    name,
    label,
    defaultValue = '',
    error = null,
    isLoading = false,
    autoComplete,
    autoFocus = false,
    id,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onDelete,
    value,
    type = 'text',
    maxLength,
    disabled = false,
    rules = [],
    placeholder,
    hidden = false,
    suffix,
    min,
    max,
    inputMaxWidth,
  } = props;

  const [internalValue, setInternalValue] = useState<string>(defaultValue.toString());
  const [inputError, setInputError] = useState<InputError | null>(error);
  let ref = createRef<HTMLInputElement>();

  if (inputRef) {
    ref = inputRef as RefObject<HTMLInputElement>;
  }

  /**
   * Register and Unregister the Input Element to the Form.
   */
  useEffect(() => {
    if (ref.current) {
      props?.form?.registerElement(ref.current, rules, name);
    }

    return () => {
      props?.form?.unregisterElement(name);
    };
  }, [ref.current]);

  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    setInputError(error);
  }, [error]);

  useEffect(() => {
    if (props.form) {
      const validationError = props.form.validate(name, rules, internalValue);

      if (validationError) {
        setInputError(validationError);
      } else if (error) {
        setInputError(error);
      } else {
        setInputError(null);
      }
    }
  }, [internalValue]);

  return (
    <div>
      <div
        className={classNames(styles.inputContainer, {
          [styles.hasError]: error && !error.isValid && true === (props?.form?.hasFormSubmitted ?? true),
          [styles.hasValue]: '' !== (value ?? '') || '' !== internalValue || placeholder,
          [styles.hidden]: hidden,
        })}
        style={{ maxWidth: inputMaxWidth }}
      >
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {/* To enable pseudo css that we can use the :placeholde-shown selector, we need to add a placeholder (empty string is enough) */}
        <input
          ref={ref}
          className={classNames({
            [styles.input]: true,
          })}
          min={min}
          max={max}
          type={type}
          autoFocus={autoFocus}
          id={id}
          name={name}
          value={internalValue}
          autoComplete={autoComplete}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event): void => {
            const newValue = event.target.value;

            if (onChange) {
              onChange(newValue, event);
            }

            setInternalValue(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          maxLength={maxLength}
        />

        {isLoading && (
          <div className={styles.loader}>
            <Loader small={true} />
          </div>
        )}

        {suffix && (
          <span
            className={styles.icon}
            onClick={() => {
              if (type === 'date') {
                return;
              }

              setInternalValue('');
              if (onDelete) {
                onDelete();
              }
            }}
          >
            {suffix}
          </span>
        )}

        {value != '' ||
          (internalValue != '' && (
            <span
              className={styles.deleteIcon}
              onClick={() => {
                setInternalValue('');
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              <Icon icon={'close'} color={themeVariables.black} />
          </span>
          ))}

      </div>

      {(inputError && false === inputError.isValid && true === (props?.form?.hasFormSubmitted ?? true)) && (
        <div key={`${name}-${inputError.errorCode}`} className={styles.error}>
          <Icon icon={'warning'} size={'20px'} color={themeVariables.errorColor} />
          <span>{inputError.message}</span>
        </div>
      )}
    </div>
  );
});
