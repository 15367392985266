import {
  CartItemFragment,
  DeliveryDestination,
  ProductBundleQuery,
  PropertyFilter,
  ShippingOption,
} from './../../@types/codegen/graphql';
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";

interface IProductConfiguratorValues {
  selectedAccessories: string[];
  selectedRelatedProducts: string[];
  preRentalStationId: string | null;
  rentalStationId: string | null;
  shippingOption: ShippingOption;
  deliveryAddress: DeliveryDestination |  null;
  deliveryTime: string | null;
  properties: PropertyFilter[];
  basketItemNote: string;
  editBasketItem: CartItemFragment | null;
  isEndDayValid: boolean;
  isStartDayValid: boolean;
  selectedProductAmount: number;
}

interface ISetProductConfigurator {
  setSelectedAccessories: SetterOrUpdater<string[]>;
  setSelectedRelatedProducts: SetterOrUpdater<string[]>;
  setPreRentalStationId: SetterOrUpdater<string | null>;
  setRentalStationId: SetterOrUpdater<string | null>;
  setShippingOption: SetterOrUpdater<ShippingOption>;
  setDeliveryTime: SetterOrUpdater<string>;
  setDeliveryAddress: SetterOrUpdater<DeliveryDestination |  null>;
  setProperties: SetterOrUpdater<PropertyFilter[]>;
  setBasketItemNote: SetterOrUpdater<string>;
  setEditBasketItem: SetterOrUpdater< CartItemFragment | null>;
  setIsStartDayValid: SetterOrUpdater<boolean>;
  setIsEndDayValid: SetterOrUpdater<boolean>;
  setSelectedProductAmount: SetterOrUpdater<number>;
}

export const editBasketItemState = atom<CartItemFragment | null>({
  key: 'editBasketItem',
  default: null,
});

export const configuratorDataState = atom<ProductBundleQuery | null>({
  key: 'configuratorData',
  default: null,
});

export const selectedAccessoriesState = atom<string[]>({
  key: 'selectedAccessories',
  default: [],
});

export const selectedRelatedProductsState = atom<string[]>({
  key: 'selectedRelatedProducts',
  default: [],
});

export const preRentalStationIdState = atom<string | null>({
  key: 'preRentalStationId',
  default: null,
});

export const rentalStationIdState = atom<string | null>({
  key: 'rentalStationId',
  default: null,
});

export const shippingOptionState = atom<ShippingOption>({
  key: 'shippingOption',
  default: ShippingOption.Pickup
});

export const deliveryAddressState = atom<DeliveryDestination | null>({
  key: 'deliveryAddress',
  default: null
});

export const deliveryTimeState = atom<string>({
  key: 'deliveryTime',
  default: '0700-0900'
});

export const propertiesState = atom<PropertyFilter[]>({
  key: 'properties',
  default: []
});

export const basketItemNoteState = atom<string>({
  key: 'basketItemNote',
  default: ''
});

export const isStartDayValidState = atom<boolean>({
  key: 'isStartDayValid',
  default: true
});

export const isEndDayValidState = atom<boolean>({
  key: 'isEndDayValid',
  default: true
});

export const selectedProductAmountState = atom<number>({
  key: 'selectedProductAmount',
  default: 1
});

export const productConfiguratorAtoms = {
  selectedAccessories: selectedAccessoriesState,
  selectedRelatedProducts: selectedRelatedProductsState,
  preRentalStationId: preRentalStationIdState,
  rentalStationId: rentalStationIdState,
  shippingOption: shippingOptionState,
  deliveryAddress: deliveryAddressState,
  properties: propertiesState,
  configuratorData: configuratorDataState,
  basketItemNote: basketItemNoteState,
  editBasketItem: editBasketItemState,
  selectedProductAmount: selectedProductAmountState,
};

export const useProductConfiguratorValues = (): IProductConfiguratorValues => ({
  selectedAccessories: useRecoilValue(selectedAccessoriesState),
  selectedRelatedProducts: useRecoilValue(selectedRelatedProductsState),
  preRentalStationId: useRecoilValue(preRentalStationIdState),
  rentalStationId: useRecoilValue(rentalStationIdState),
  shippingOption: useRecoilValue(shippingOptionState),
  deliveryAddress: useRecoilValue(deliveryAddressState),
  deliveryTime: useRecoilValue(deliveryTimeState),
  properties: useRecoilValue(propertiesState),
  basketItemNote: useRecoilValue(basketItemNoteState),
  editBasketItem: useRecoilValue(editBasketItemState),
  isStartDayValid: useRecoilValue(isStartDayValidState),
  isEndDayValid: useRecoilValue(isEndDayValidState),
  selectedProductAmount: useRecoilValue(selectedProductAmountState),
});

export const useSetProductConfigurator = (): ISetProductConfigurator => ({
  setSelectedAccessories: useSetRecoilState(selectedAccessoriesState),
  setSelectedRelatedProducts: useSetRecoilState(selectedRelatedProductsState),
  setPreRentalStationId: useSetRecoilState(preRentalStationIdState),
  setRentalStationId: useSetRecoilState(rentalStationIdState),
  setShippingOption: useSetRecoilState(shippingOptionState),
  setDeliveryAddress: useSetRecoilState(deliveryAddressState),
  setDeliveryTime: useSetRecoilState(deliveryTimeState),
  setProperties: useSetRecoilState(propertiesState),
  setBasketItemNote: useSetRecoilState(basketItemNoteState),
  setEditBasketItem: useSetRecoilState(editBasketItemState),
  setIsStartDayValid: useSetRecoilState(isStartDayValidState),
  setIsEndDayValid: useSetRecoilState(isEndDayValidState),
  setSelectedProductAmount: useSetRecoilState(selectedProductAmountState),
});
